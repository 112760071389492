export const getSeo = (title?: string) => {
  return [
    { title: `Kota | Insurance and Retirement for Global Teams ${title ? ` | ${title}` : ''}` },
    {
      name: 'description',
      content:
        'Financial benefits for global teams, set-up in less than 5 minutes. Enrol your team in local health insurance and pensions from local and global providers',
    },
    { name: 'og:image', content: 'https://my.kota.io/images/kota_og.png' },
    { name: 'og:image:alt', content: 'Kota' },
    { name: 'og:image:width', content: '1200' },
    { name: 'og:image:height', content: '630' },
    { name: 'og:title', content: 'Kota | Insurance and Retirement for Global Teams' },
    {
      name: 'og:description',
      content:
        'Financial benefits for global teams, set-up in less than 5 minutes. Enrol your team in local health insurance and pensions from local and global providers',
    },
    { name: 'og:url', content: 'https://my.kota.io' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:image', content: 'https://my.kota.io/images/kota_og.png' },
    { name: 'twitter:image:alt', content: 'Kota' },
    { name: 'twitter:title', content: 'Kota | Insurance and Retirement for Global Teams' },
    {
      name: 'twitter:description',
      content:
        'Financial benefits for global teams, set-up in less than 5 minutes. Enrol your team in local health insurance and pensions from local and global providers',
    },
    { name: 'twitter:url', content: 'https://my.kota.io' },
    { name: 'robots', content: 'noindex, nofollow' },
  ];
};
